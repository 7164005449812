import { PaginatedResponse, Status } from '.';
import { VariantsV2 } from './catalog-interface-v2';
import { SortFilter } from './orders-interface';

export interface MyProductsResponse
  extends PaginatedResponse<MyProductsResult> {
  brands: string[];
}

export interface MyProductsResult {
  _id: string;
  title: string;
  brand: string;
  catalogId: string;
  printProviderId: string;
  status: Status;
  images: Images[];
  inventory: string;
  properties: MyProductProperty[];
  createdAt: string;
  updatedAt: string;
  listingId?: number;
  savingDesign?: boolean;
  description: string;
  isAnyFieldsMissing: boolean;
}

export interface MyProductProperty {
  propertyName: string;
  propertyValues: PropertyValue[];
}

export interface PropertyValue {
  value: string;
  meta?: Meta;
}

export interface Meta {
  colorHex: string;
  productColorID: number;
}

export interface Images {
  imageUrl: string;
  id: string;
}

export interface MyProductsQueryParams {
  search?: string;
  printProviders?: string[];
  brands?: string[];
  status?: string;
  page?: number;
  sortBy?: SortFilter;
  sampleOrder?: boolean;
}

export interface SingleProductResponse {
  data: {
    _id: string;
    title: string;
    description: string;
    tags: string[];
    status: Status;
    isPersonalizable: boolean;
    personalizationInstructions: string;
    model: string;
    printProviderId: string;
    catalogId: string;
    publishingSettings: PublishingSettings;
    editorData?: any;
    variants: SingleProductVariant[] | VariantsV2[];
    images: Images[];
    shippingProfiles: ShippingProfile[];
  };
}

export interface SingleProductVariant {
  id: string;
  properties: SingleProductProperty[];
  mrsp: number;
  cost: number;
  inStock: number;
}

export interface SyncingOptions {
  syncProductTitle: boolean;
  syncDescription: boolean;
  syncVariants: boolean;
  syncMockups: boolean;
  syncShippingProfiles: boolean;
}

export interface PublishingSettings {
  showInStore: boolean;
  variantsVisibility: string;
  syncingOptions: SyncingOptions;
  shippingProfile: string | number;
}

export interface SingleProductQueryParams {
  productId: string | undefined;
  fromMockups?: boolean;
  triggerUpdate?: boolean;
}

export interface SingleProductProperty {
  propertyName: string;
  propertyValue: string;
  meta?: Meta;
}

export interface UpdateProductDetailsQueryParams {
  productId: string | undefined;
  body: UpdateProductDetailsBody;
}

export interface UpdateProductDetailsBody {
  title: string;
  tags: string[];
  description: string;
  variants: { id: string; mrsp: number }[];
  publishingSettings: PublishingSettings;
  isPersonalizable: boolean;
  personalizationInstructions?: string;
  publishProduct?: boolean;
  publishChanges?: boolean;
}

export interface ShippingProfile {
  title: string;
  isEverbeeShippingProfile: boolean;
  shippingProfileId: number;
  primaryCost: Cost;
  secondaryCost: Cost;
  shippingClass: string | null;
  shippingRatesId: string;
  minProcessingDays: number;
  maxProcessingDays: number;
}

interface Cost {
  amount: number;
  divisor: number;
  currencyCode: string;
}
export interface MugModel {
  model: '15OZ' | '11OZ';
}

export enum ProductType {
  ApparelLight = 'Apparel Lightweight',
  ApparelHeavy = 'Apparel Heavyweight',
  Mug = 'Drinkware',
  Ornament = 'Super Lightweight',
}

export enum ProductName {
  ColoredMug = 'Colored',
  WhiteMug = 'White Mug',
  CircleOrnament = 'Circle',
  OvalOrnament = 'Oval',
  HeartOrnament = 'Heart',
  StarOrnament = 'Star',
  TreeOrnament = 'Tree',
  BellOrnament = 'Bell',
  LondonOrnament = 'Snowflake',
  SnowflakeOrnament = 'Snowflake-C',
  BerlinOrnament = 'Berlin',
  CeramicStarOrnament = 'Star-C',
  RectangleOrnament = 'Rectangle',
  TaperedOrnament = 'Tapered',
  PragueOrnament = 'Prague',
  BeneluxOrnament = 'Benelux',
  AluminiumOvalOrnament = 'Oval-A',
}

export enum MugType {
  _11oz = '11OZ',
  _15oz = '15OZ',
}

export enum MugDimensions {
  _15OZ = 340,
  _11OZ = 324,
}
